.custom-btn {
    background-color: white;
    color: black;
    /* border: 2px solid white; */
    border-radius: 5px;
    font-size: 16px;
    transition: background-color 0.3s, color 0.3s, border-color 0.3s;
  }
  
  .custom-btn:hover {
    background-color: #212529;
    color: white;
    /* border: 2px solid black; */
  }
  