.with-back-plate::before {
    content: "";
    z-index: -1
}

.with-back-plate{
    position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Adjust the color and opacity as needed */
  opacity: 0.5; /* Adjust the overlay image opacity as needed */
}

.with-back-plate::before {
    content: "";
    z-index: -1;
  
  }

.zindex {
    z-index: -1;
}

.img-custom{
    box-shadow: 10px 10px rgb(255, 255, 255);
}

@media (max-width: 768px) {
    .img-custom {
      box-shadow: 4px 4px rgb(255, 255, 255);
    }
  }

@media (min-width: 768px) {
    .img-custom {
      box-shadow: 5px 5px rgb(255, 255, 255);
    }
  }
  
  @media (min-width: 1200px) {
    .img-custom {
      box-shadow: 10px 10px rgb(255, 255, 255);
    }
  }